<template>
  <div class="ht-chat-view">
    <ht-message-list
      class="message-list"
      :message-list="chatList"
      ref="messageList"
    />
    <ht-input-view
      :class="input_chat"
      @onsend="sendInputMessage"
      v-model:checkedValue="checked"
      ref="chatInputView"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import HtMessageList from "./htMessageList.vue";
import HtInputView from "./htInputView.vue";
import { connectState } from "../../../utils/wyUtil";
import http from "@/utils/request";

export default {
  name: "htChatView",
  components: {
    HtInputView,
    HtMessageList,
  },
  data() {
    return {
      HTSDK: window.HTSDK,
      sensitiveWords: [],
      checked: false,
      isTeacher: false, // 待增加教师检测
      chatList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...connectState("live", [
      "isFullScreen",
      "sideViewFold",
      "messageList",
      "classStatus",
    ]),
  },
  watch: {
    messageList(val) {
      this.filterMessages(this.checked);
    },
    checked(val) {
      this.filterMessages(val);
    },
  },
  mounted() {
    this.HTSDK = window.HTSDK;
    this.HTSDK.on(this.HTSDK.EVENT.CHAT.SEND, (res) => {
      this.$store.commit("live/ADD_BARRAGES", [res]);
      this.keepMessageListOnBottom();
    });
    // 禁止发言
    this.HTSDK.on(this.HTSDK.EVENT.CHAT.DISABLE, (res) => {
      const me = this.$store.state.loading.ME;
      if (!me || res.xid * 1 !== me.xid * 1) {
        this.$store.commit("live/CLEAR_USER_MESSAGE", res.xid);
      }
    });
  },
  methods: {
    filterMessages(checked) {
      if (!this.checked) {
        this.chatList = this.messageList;
        return false;
      }
      this.chatList = this.messageList.filter(
        (item) =>
          item.role === "spadmin" ||
          item.role === "admin" ||
          item.uid * 1 === this.userInfo.id * 1
      );
    },
    keepMessageListOnBottom() {
      this.$refs.messageList.scrollMessagesToBottom();
    },
    // 发送消息
    sendInputMessage(inputText) {
      this.sendUserMessage(inputText);
    },
    async sendUserMessage(text) {
      if (!text) return false; // Empty
      if (!this.isTeacher) {
        // 处理敏感词
        const words = this.sensitiveWords;
        let regStr = "";
        for (let i = 0; i < words.length; i++) {
          const item = words[i];
          // 正则匹配的敏感词
          if (!regStr) {
            regStr = item.word;
          } else {
            regStr += `|${item.word}`;
          }
        }
        if (regStr) {
          text = text.replace(new RegExp(regStr, "g"), (match) =>
            new Array(match.length).fill("*").join("")
          );
        }
      }
      const res = await http(`/hcp/base/base/checkWords`, {
        method: "post",
        data: {
          content: text,
          deviceType: "web",
        },
      });
      console.log("11111111111111111111111111111", res);
      if (res) {
        this.HTSDK.emit(
          this.HTSDK.EVENT.CHAT.SEND,
          {
            type: "matchKey",
            msg: text,
          },
          (res) => {
            console.log("chat:send", res, this.$refs.chatInputView);
            if (res.code === 0) {
              this.$store.commit("live/ADD_BARRAGES", [res.data]);
              this.$refs.chatInputView.clearInput();
              this.keepMessageListOnBottom();
            } else {
              this.$message({ message: res.msg, type: "warning" });
            }
          }
        );
      } else {
        this.$message({ message: '发送失败，内容含有敏感词！', type: "error" })
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ht-chat-view {
  height: calc(100vh - 340px);
  display: flex;
  flex-direction: column;

  .message-list {
    flex: 1;
  }
  .input_chat {
    flex: none;
  }
}
</style>
