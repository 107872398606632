<template>
  <div class="ht_tool_bar">
    <div class="volume_slider">
      <i class="open_volume" v-if="volume > 0" @click="localVolume = 0"></i>
      <i class="close_volume" v-else @click="localVolume = 1"></i>
      <el-slider
        v-model="localVolume"
        class="small_bar"
        :show-tooltip="false"
        :max="1"
        :step="0.1"
      ></el-slider>
      <span>{{ localVolume * 100 }}%</span>
    </div>
    <div>
      <img
        class="icon"
        v-show="!barragePause"
        src="../../../assets/img/barrageon.png"
        title="打开弹幕"
        @click="toggleBarrage"
      />
      <img
        class="icon"
        v-show="barragePause"
        src="../../../assets/img/barrageclose.png"
        title="关闭弹幕"
        @click="toggleBarrage"
      />
    </div>
    <img
      class="icon"
      v-if="showCamera"
      src="../../../assets/img/camera_open.png"
      @click="toggleCamera"
      alt=""
    />
    <img
      class="icon"
      v-else
      src="../../../assets/img/camera_gray@2x.png"
      @click="toggleCamera"
      alt=""
    />
    <img
      class="icon"
      v-if="applyStatus === 2"
      src="../../../assets/img/raise_hand@2x.png"
      @click="downPlatform"
      title="关闭连麦"
      alt=""
    />
    <el-tooltip placement="top" v-show="classStatus === 1">
      <template #content>
        <ul class="lines-list">
          <li
            v-for="line in linesList"
            :key="line.key"
            :class="selectLine === line.key ? 'active_line' : null"
            @click="selectLineChange(line.key)"
          >
            {{ line.label }}
          </li>
        </ul>
      </template>
      <img
        title="切换线路"
        class="icon"
        src="../../../assets/img/lines@2x.png"
        alt=""
      />
    </el-tooltip>
    <img
      class="icon"
      v-if="sideViewFold"
      @click="toggleSideViewFold"
      src="../../../assets/img/shrnk_left@2x.png"
      alt=""
    />
    <img
      class="icon"
      v-else
      @click="toggleSideViewFold"
      src="../../../assets/img/expend_r.png"
      alt=""
    />
    <img
      class="icon"
      src="../../../assets/img/fullScreen.png"
      :title="isFullScreen ? '退出全屏' : '全屏'"
      @click="fullScreen"
      alt=""
    />
    <div id="userCamera"></div>
    <el-dialog
      title="连麦提示"
      v-model="audioConfirm.show"
      :center="true"
      width="420px"
      class="commonDialog showAudioConfirm"
    >
      <div class="content">
        <p>主讲人邀请你开麦，确认接受吗？</p>
        <p>（15秒后默认拒绝）</p>
      </div>
      <br />
      <div class="dialog-footer">
        <el-button round class="btn close" @click="studentAudioConfirmNo"
          >拒绝</el-button
        >
        <el-button
          round
          type="primary"
          class="btn"
          @click="studentAudioConfirmYes"
        >
          接受（{{ audioConfirm.second }}）
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import { connectState, fullScreenUtils } from "../../../utils/wyUtil";

export default {
  name: "htToolView",
  data() {
    return {
      localVolume: 1,
      HTSDK: window.HTSDK,
      linesList: [], // 线路列表，欢拓返回
      selectLine: 0, // 选择的线路
      visible: false,
      cameraOpen: true,
      xid: null,
      initial: false,
      // 开麦状态确认相关数据
      audioConfirm: {
        show: false, // 是否显示弹窗
        interval: null, // 定时器
        second: 0, // 倒计时计数剩余秒数
        studentId: 0, // 学生id
      },
    };
  },
  mounted() {
    this.HTSDK = window.HTSDK;
    document.addEventListener("fullscreenchange", this.screenListener);
    document.addEventListener("mozfullscreenchange", this.screenListener);
    document.addEventListener("webkitfullscreenchange", this.screenListener);
    document.addEventListener("msfullscreenchange", this.screenListener);
    // 线路选择列表
    this.HTSDK.on(this.HTSDK.EVENT.NETWORK.LIST, (res) => {
      this.linesList = res || [];
    });
    this.HTSDK.on(this.HTSDK.EVENT.RTC.LOCAL_VOLUME, (res) => {
      // 直播开始后两秒返回一次
      console.warn("REMOTE_VOLUME=>", res);
    });
    this.HTSDK.on(this.HTSDK.EVENT.RTC.PLAY_LOCAL, async (params) => {
      if (window.navigator.mediaDevices.getUserMedia) {
        await window.navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
      }
      // 这里播用户的流
      params.callback("userCamera");
      this.updateModelState({ key: "applyStatus", data: 2 });
    });
    // 本地流的状态，推流成功之后每两秒触发一次
    this.HTSDK.on(this.HTSDK.EVENT.RTC.LOCAL_STATS, () => {
      if (this.cameraOpen) {
        this.cameraOpen = false;
        this.HTSDK.emit(
          this.HTSDK.EVENT.RTC.VIDEO_CLOSE,
          { xid: 0, job: 2 },
          () => {}
        );
      }
    });
    this.HTSDK.on(this.HTSDK.EVENT.RTC.VIDEO_OPEN, () => {
      this.cameraOpen = true;
    });
    this.HTSDK.on(this.HTSDK.EVENT.RTC.VIDEO_CLOSE, () => {
      this.cameraOpen = false;
    });
    this.HTSDK.on(this.HTSDK.EVENT.RTC.USER_VIDEO_OPEN, () => {
      this.cameraOpen = true;
    });
    this.HTSDK.on(this.HTSDK.EVENT.RTC.USER_VIDEO_CLOSE, () => {
      this.cameraOpen = false;
    });

    // 用户列表，用户上讲台的状态改变了就会触发
    this.HTSDK.on(this.HTSDK.EVENT.RTC.UP_USER_LIST, (res) => {
      this.upUserList = res;
    });
    // 用户被请下讲台
    this.HTSDK.on(this.HTSDK.EVENT.RTC.KICK, () => {
      this.updateModelState({ key: "applyStatus", data: 0 });
      this.$message({ message: "老师断开了连麦", type: "warning" });
      if (this.$store.state.live.liveMode === 0) {
        this.HTSDK.flvPlayer.fire("#bypassStream");
      } else {
        this.HTSDK.flvPlayer.fire("#VIDEO-CONTAINER");
      }
    });
    // 邀请上讲台会触发此事件，在这里处理学生收到邀请后的逻辑
    this.HTSDK.on(this.HTSDK.EVENT.RTC.INVITE, (res) => {
      if (
        res.inviteStatus === "await" &&
        res.uid * 1 === this.userInfo.userId * 1
      ) {
        console.log("----邀请连麦:", res);
        this.onStudentAudioConfirm();
      }
    });
    // 学员接受或者拒绝邀请后会触发此事件
    this.HTSDK.on(this.HTSDK.EVENT.RTC.RESPOND_INVITE, () => {});
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...connectState("live", [
      "isFullScreen",
      "sideViewFold",
      "applyStatus",
      "showCamera",
      "barragePause",
      "classStatus",
      "volume",
    ]),
  },
  watch: {
    localVolume(val) {
      this.handleVolume(val);
    },
  },
  methods: {
    checkClassStatus(status) {
      return this.classStatus === status;
    },
    updateModelState(payload) {
      this.$store.commit("live/UPDATE_STATE_VALUE", payload);
    },
    toggleBarrage() {
      this.updateModelState({
        key: "barragePause",
        data: !this.$store.state.live.barragePause,
      });
    },
    switchView() {
      this.updateModelState({
        key: "switch",
        data: !this.$store.state.live.switch,
      });
    },
    // 改变音量
    handleVolume(val) {
      this.updateModelState({ key: "volume", data: val });
    },
    toggleCamera() {
      this.updateModelState({
        key: "showCamera",
        data: !this.$store.state.live.showCamera,
      });
    },
    // 申请连麦
    applyPlatform() {
      this.HTSDK.emit(
        this.HTSDK.EVENT.RTC.APPLY,
        { xid: this.userInfo.id },
        (res) => {
          if (res.code === 0) {
            this.$message({
              message: "已申请连麦，等待老师回复",
              type: "warning",
            });
            this.$store.state.live.applyStatus = 1;
          }
        }
      );
    },
    // 取消申请上讲台
    cancelPlatform() {
      this.HTSDK.emit(
        this.HTSDK.EVENT.RTC.CANCEL,
        { xid: this.userInfo.id },
        (res) => {
          if (res.code === 0) {
            this.$message({ message: "已取消连麦", type: "warning" });
            this.$store.state.live.applyStatus = 0;
          }
        }
      );
    },
    // 下讲台
    downPlatform() {
      // 主动下讲台
      this.HTSDK.emit(
        this.HTSDK.EVENT.RTC.DOWN,
        { xid: this.xid || 0 },
        (res) => {
          if (res.code === 0) {
            this.$message({ message: "连麦已断开", type: "warning" });
            this.updateModelState({ key: "applyStatus", data: 0 });
          }
          if (this.$store.state.live.liveMode === 0) {
            this.HTSDK.flvPlayer.fire("#bypassStream");
          } else {
            this.HTSDK.flvPlayer.fire("#VIDEO-CONTAINER");
          }
        }
      );
    },
    /**
     * 学生被老师开麦后，进行确认的弹窗
     * @param studentId 学生的id
     */
    onStudentAudioConfirm() {
      // 15秒后自动关闭弹框
      let count = 0;
      this.audioConfirm.second = 15;
      if (this.audioConfirm.interval) {
        clearInterval(this.audioConfirm.interval);
      }
      this.audioConfirm.interval = setInterval(() => {
        if (count === 15) {
          this.studentAudioConfirmNo();
        }
        this.audioConfirm.second = 15 - count;
        count += 1;
      }, 1000);
      this.audioConfirm.show = true;
    },
    studentAudioConfirmYes() {
      this.audioConfirm.show = false;
      clearInterval(this.audioConfirm.interval);
      if (window.navigator.mediaDevices.getUserMedia) {
        window.navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: true,
          })
          .then((res) => {
            this.HTSDK.emit(
              this.HTSDK.EVENT.RTC.RESPOND_INVITE,
              { type: "accept" },
              () => {}
            );
          })
          .catch((err) => {
            this.HTSDK.emit(
              this.HTSDK.EVENT.RTC.RESPOND_INVITE,
              { type: "accept" },
              () => {}
            );
          });
      }
    },
    studentAudioConfirmNo() {
      this.$message.error("已拒绝主讲的开麦邀请");
      this.audioConfirm.show = false;
      clearInterval(this.audioConfirm.interval);
      this.HTSDK.emit(
        this.HTSDK.EVENT.RTC.RESPOND_INVITE,
        { type: "reject" },
        () => {}
      );
    },
    closeCamera() {
      this.HTSDK.emit(
        this.HTSDK.EVENT.RTC.VIDEO_CLOSE,
        { xid: 0, job: 1 },
        () => {}
      );
    },
    fullScreen() {
      const ele = document.getElementById("main_container");
      if (this.isFullScreen) {
        fullScreenUtils.exitFullScreen();
      } else {
        fullScreenUtils.launchFullScreen(ele);
      }
    },
    screenListener() {
      this.updateModelState({ key: "isFullScreen", data: !this.isFullScreen });
    },
    toggleSideViewFold() {
      this.updateModelState({ key: "sideViewFold", data: !this.sideViewFold });
    },
    selectLineChange(value) {
      this.selectLine = value;
      this.updateModelState({ key: "lineSetting", data: true });
      this.HTSDK.mediaCore.setLine(value, (res) => {
        console.log("-----", res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ht_tool_bar {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  transition: width 0.2s linear;

  .icon {
    display: block;
    width: 34px;
    height: 34px;
    margin: 0 10px;
    cursor: pointer;
  }

  .volume_slider {
    width: 210px;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 11px;
    }
    .open_volume {
      background: url("../../../assets/img/loudspeaker.png") center center
        no-repeat;
      background-size: 100% 100%;
    }
    .close_volume {
      background: url("../../../assets/img/silence.png") center center no-repeat;
      background-size: 100% 100%;
    }

    .small_bar {
      width: 120px;
    }

    span {
      display: inline-block;
      margin-left: 14px;
      font-size: 12px;
      color: rgba(144, 144, 144, 1);
    }
  }
}

#userCamera {
  display: none;
}

.showAudioConfirm {
  width: 100%;
  .content {
    padding: 30px 0 40px;
  }
}

.lines-list {
  background: rgba(51, 59, 80, 0.6);
  width: 64px;

  li {
    text-align: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    line-height: 30px;
    margin: 7px 0;
    cursor: pointer;
  }
  .active_line {
    border: 1px solid #3377FF;
    border-radius: 3px;
  }
}
</style>
