<template>
    <div>
      <ht-live v-if="HTSDKReady" ></ht-live>
      <enter-room-loading v-else></enter-room-loading>
    </div>
</template>

<script>
/* eslint-disable */
import htLive from './htLive.vue';
import EnterRoomLoading from './components/EnterRoom.vue';
import { connectState } from '../../utils/wyUtil';
import { talkFunInit } from './htUtil';

export default {
  components: { EnterRoomLoading, htLive },
  data() {
    return {
      HTSDKReady: false,
      HTSDK: null,
      courseId: this.$route.params.id,
      courseDetailId: this.$route.params.detailId,
    };
  },
  computed: {
    ...connectState('course', ['liveAccessToken']),
  },
  watch: {
    liveAccessToken(val) {
      if (val) {
        this.HTSDKReady = true;
        window.HTSDK=talkFunInit(val) || null;
        this.HTSDK=window.HTSDK;
        // Vue.prototype.HTSDK = talkFunInit(val) || null;
      }
    },
    HTSDKReady(val) {
      if (val) {
        this.initListener();
      }
    },
  },
  created() {
    this.getSectionInfo();
    this.getAccessToken();
  },
  methods: {
    getSectionInfo() {
      this.$store.dispatch('course/fetchSectionDetail',
        { courseDetailId: this.courseDetailId });
    },
    getAccessToken() {
      this.$store.dispatch('course/liveCourseJoin', {
        courseId: this.courseId,
        courseDetailId: this.courseDetailId,
      });
    },
    initListener() {
      // 监听直播间异常
      this.HTSDK.on(this.HTSDK.EVENT.RTC.ERROR, (res) => {
        if (res.code === 1220) {
          return this.$message({ message: '老师没有开启连麦功能哦~', type: 'warning' });
        } if (res.code === 1202) {
          return this.$message({ message: res.msg, type: 'warning' });
        }
        this.$message({ message: res.msg, type: 'warning' });
        // this.$router.replace('/404');
      });
      //
      this.HTSDK.on(this.HTSDK.EVENT.SOCKET.CONNECT, () => {
        this.HTSDKReady = true;
      });
      // 获取直播间人数
      this.HTSDK.on(this.HTSDK.EVENT.MEMBER.TOTAL, (total) => {
        this.$store.state.live.onLineNumber = total;
        this.getMembers();
      });
      // 连麦获取设备错误
      this.HTSDK.on(this.HTSDK.EVENT.RTC.DEVICE_ERROR, (res) => {
        if (res.type === 'error') {
          this.$message({ message: res.msg, type: 'warning' });
        }
      });
    },
    // 获取直播成员
    getMembers() {
      this.HTSDK.emit('member:list', { page: 1, size: 100 }, (res) => {
        this.$store.state.live.memberList = res;
      });
    },
  },
};
</script>
