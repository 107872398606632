<template>
  <div>
    <span>正在进入直播间......</span>
  </div>
</template>
<script>
export default {
  name: 'enterRoomLoading',
};
</script>
<style lang="scss" scoped>
</style>
