<template>
    <div class="chat-messages">
        <div class="chat-messages-scollcontainer" ref="scrollContainer">
            <ht-message-item v-for="(item,index) in chatList"
                             :key="index"
                             :message="item"/>
        </div>
    </div>

</template>

<script>
import HtMessageItem from './htMessageItem.vue';

export default {
  name: 'htMessageList',
  components: { HtMessageItem },
  data() {
    return {};
  },
  props: {
    messageList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    chatList() {
      console.log('----list', this.messageList);
      return this.messageList;
    },
  },
  methods: {
    // 聊天消息滚动到底部
    scrollMessagesToBottom() {
      this.$nextTick(() => {
        const messageListNode = this.$refs.scrollContainer;
        if (!messageListNode) {
          return;
        }
        messageListNode.scrollTop = messageListNode.scrollHeight;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.chat-messages {
    position: relative;
    .chat-messages-scollcontainer {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 10px 0;
        /* for Chrome */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
</style>
