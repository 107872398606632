<template>
  <div class="ht-chat-bottom">
    <div class="input-btns">
      <el-popover
        placement="top-start"
        width="220"
        trigger="hover"
        v-model="visible">
        <ht-emotion @emotion="handleEmotion"/>
        <template #reference>
          <a title="选择表情">
            <img
              src="../../../assets/img/emotion_face@2x.png"
              style="height: 18px;" alt=""/>
          </a>
        </template>
      </el-popover>
      <el-checkbox v-model="checked">只看老师聊天</el-checkbox>
    </div>
    <div class="input-items"
         @keydown.enter.prevent.exact="sendByEnter"
         @keydown.enter.shift.prevent.exact="inputText=inputText+'\n'"
         @keydown.enter.ctrl.prevent.exact="inputText=inputText+'\n'">
      <el-input
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 4}"
        maxlength="200"
        class="commonInput"
        resize="none"
        placeholder="请输入"
        v-model="inputText"
      />
      <div @click="send" class="send" title="快捷键Enter">发送</div>
    </div>

  </div>
</template>

<script>
  /* eslint-disable */
  import { mapGetters } from 'vuex';
  import HtEmotion from '@/components/HTEmotion.vue';

  export default {
    name: 'htInputView',
    components: {
      HtEmotion,
    },
    props:{
      checkedValue:Boolean,
    },
    data() {
      return {
        visible: false,
        inputText: '',
        count: 0,
        autoSendInterval: null,
        checked:false,
      };
    },
    watch:{
      checkedValue(val){
        this.checked=val
      },
      checked(val){
        this.$emit("update:checkedValue",val)
      }
    },
    computed: {
      ...mapGetters(['randomStudentId']),
    },
    methods: {
      checkChange() {
        this.$emit('change');
      },
      // 发送消息
      send() {
        this.$emit('onsend', this.inputText);
      },
      sendByEnter(e) {
        console.log("----",e)
        const keyCode = window.event ? e.keyCode : e.which;
        if (keyCode !== 13) {
          console.log('prevent send, keyCode=', keyCode, e);
        } else {
          this.send();
        }
      },
      clearInput() {
        this.inputText = '';
      },
      handleEmotion(obj) {
        const ele = document.querySelector('.el-textarea textarea');
        const index = this.getCursorPosition(ele);
        this.inputText = this.inputText.substring(0, index) + obj + this.inputText.substring(index);
        const newPosition = index + obj.length;
        this.setSelectCursor(ele, newPosition);
        this.visible = false;
      },
      getCursorPosition(ctrl) {
        let CaretPos = 0;
        if (document.selection) { // IE Support
          ctrl.focus();
          const Sel = document.selection.createRange();
          Sel.moveStart('character', -ctrl.value.length);
          CaretPos = Sel.text.length;
        } else if (ctrl.selectionStart || ctrl.selectionStart == '0') { // Firefox support
          CaretPos = ctrl.selectionStart;
        }
        return (CaretPos);
      },
      setSelectCursor(el, index) { /* 绑定在onfocus事件上 */
        el.focus();
        if (document.selection) {
          const sel = el.createTextRange();
          sel.moveStart('character', index);
          sel.collapse();
          sel.select();
        } else if (typeof el.selectionStart === 'number' && typeof el.selectionEnd === 'number') {
          el.selectionStart = el.selectionEnd = index;
        }
      },
      placeCaret(el, atStart) {
        el.focus();
        if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
          const range = document.createRange();
          range.selectNodeContents(el);
          range.collapse(atStart);
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (typeof document.body.createTextRange !== 'undefined') {
          console.log('select B');
          const textRange = document.body.createTextRange();
          textRange.moveToElementText(el);
          textRange.collapse(atStart);
          textRange.select();
        }
      },
    },
  };
</script>

<style lang="scss">

  .ht-chat-bottom {
    min-height: 60px;
    border-top: 1px solid #EAEAEA;
    overflow: hidden;
    padding: 0 10px 10px;

    .input-btns {
      display: flex;
      align-items: center;
      padding: 10px 0;
      justify-items: space-between;
      justify-content: space-between;
    }

    .input-items {
      display: flex;
      align-items: flex-end;

      .commonInput .el-textarea__inner {
        /*background-color: rgba(0, 136, 208, 0.2);*/
        background:#FFF;
      }
    }

    .send {
      background:#3377FF;
      border-radius: 16px;
      color: white;
      width: 56px;
      text-align: center;
      height: 24px;
      font-size: 14px;
      line-height: 24px;
      margin-left: 4px;
      cursor: pointer;

      &:hover {
        opacity:0.6;
      }
    }

    .el-textarea__inner {
    }
  }
</style>
