/* eslint-disable */
/**
 * 欢拓实例初始化
 * @param accessToken
 * @returns {boolean|*}
 */
export const talkFunInit = (accessToken) => {
  const Sdk = window.Sdk;
  if (typeof Sdk === 'undefined') {
    return false;
  } else {
    if (window.HTLive == null) {
      /** 单例模式判断是否已经实例化了该对象 */
      const HT = new Sdk({
        accessToken: accessToken,
        client: 0,
      })
      window.HTLive = HT;
    }
  }
  return window.HTLive
};
