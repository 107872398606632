<template>
    <div class="message" :class="{right:userInfo.id*1===chatMsg.uid*1}" :key="chatMsg.time">
<!--        <img class="avatar" :src="message.avatar">-->
        <div class="msg-container">
            <div class="name-box">
                <img class="icon" src="../../../assets/img/icon_chat_special.png" alt=""/>
                <span class="name">{{chatMsg.nickname}}</span>
                <span class="teacher_label" v-if="chatMsg.role==='spadmin'">老师</span>
                <span class="teacher_label" v-else-if="chatMsg.role==='admin'">助教</span>
                <span class="time">{{wrapTime(chatMsg.nickname)}}</span>
            </div>
            <div class="bubble">
                <p v-html="formatMsg(chatMsg.msg)"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ubb2img } from '@/utils/htTool';
import { formatDate } from '@/utils/time';

export default {
  name: 'htMessageItem',
  props: {
    message: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    chatMsg() {
      console.log('----cc', this.message);
      return this.message;
    },
  },
  methods: {
    formatMsg(msg) {
      return ubb2img(msg);
    },
    wrapTime(time) {
      return formatDate(Math.floor(time), 'HH:mm:ss');
    },
  },
};
</script>

<style scoped lang="scss">

    .message {
        padding: 10px 10px 16px;
        clear: both;
        margin-bottom: 2px;
        overflow: hidden;

        .avatar {
            float: left;
            border-radius: 50%;
            margin-right: 8px;
            width: 34px;
            height: 34px;
            object-fit: cover;
        }

        .msg-container {
            position: relative;
        }
        .icon {
            display: none;
        }
        .name-box{
          margin-bottom:10px;
        }
        .name {
            font-size: 14px;
            font-weight: 400;
            color: #909090;
            height: 20px;
            line-height: 20px;
        }

        .teacher_label {
            display: inline-block;
            background-color: #3377FF;
            color: #FFFFFF;
            font-size: 12px;
            padding: 0px 7.5px;
            border-radius: 10px;
            height: 20px;
            line-height: 20px;
            transform: scale(0.8);
        }

        .time {
            /*padding-left: 5px;*/
            font-size: 8px;
            color: rgba(144, 144, 144, 1);
            float: right;
            height: 20px;
            line-height: 20px;
        }

        .bubble {
            display: inline-block;
            min-width: 52px;
            max-width: 248px;
            background: #FAFAFA;
            color: #313131;
            font-size: 14px;
            line-height: 18px;
            padding: 6px 10px;
            border-radius: 5px;
            word-wrap: break-word;

            img {
                vertical-align: middle;
            }
            .corner {
                position: absolute;
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-right: 7px solid #FAFAFA;
                left: -6px;
                top: 30px;
            }
        }
    }

    .message.right {
        > img {
            float: right;
            margin-left: 10px;
            margin-right: 0;
        }

        .name {
            color: #3377FF;
        }
        .icon {
            display: inline-block;
            width: 10px;
            height: 12px;
            vertical-align: middle;
            margin-right: 5px;
        }
        .msg-container {
            text-align: right;
        }

        .time {
            float: left;
        }

        .bubble {
            border-radius: 5px;
            box-shadow: 0 1px 0 0 #3377FF;
            text-align: left;

            .corner {
                position: absolute;
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-left: 7px solid #FAFAFA;
                border-right: initial;
                left: initial;
                right: -6px;
                top: 30px;
            }
        }
    }
</style>
