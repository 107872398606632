<template>
  <div class="live_page_conatiner">
    <live-nav :name="sectionDetail.sectionName">
    <div v-if="checkClassStatus(1)">
            <span class="label">
              已直播：<span class="primary_text">{{timeInfo.restTime}}</span>
            </span>
    </div>
  </live-nav>
    <div class="main_container_wrapper">
      <section class="main_container" id="main_container">
        <!-- 未上课提示 -->
        <div class="file_video_tip" v-if="checkClassStatus(0)||checkClassStatus(-1)">
          <div class="bgImg waitClass"></div>
          <p class="tip">课程{{checkClassStatus(0)?'即将开始':'已结束'}}</p>
          <div v-if="sectionDetail.courseId" class="course">
            <p>主题：{{sectionDetail.courseName}}</p>
            <p>时间：{{getCourseTime()}}</p>
            <p>主讲：{{sectionDetail.teacherName}}</p>
          </div>
        </div>
        <main-player :class="switchMode?'camera_box':'main_box'"/>
        <ht-camera-view :class="switchMode?'main_box':'camera_box'"/>
        <img v-show="showCamera"
             src="../../assets/img/qiehuan@2x.png"
             class="switch_view"
             @click="handleSwitchView"
             alt=""/>
        <ht-tool-view class="tool_view"/>
        <!--              // 弹幕-->
        <ht-barrage :arr="barrages"
                    :isPause="barragePause"
                    :percent="50"/>
        <text-go>{{`${userInfo.userName}_${userInfo.userId}`}}</text-go>
      </section>
      <section class="side_view" :class="sideViewFold?'hide_side':'show_side'">
        <teacher-view/>
        <div class="public-notice" v-show="noticeVisible">
          <img class="close_btn"
               src="../../assets/img/close@2x.png"
               @click="closeNotice" alt=""/>
          <p>公告</p>
          <div>{{notice}}</div>
        </div>
        <live-tabs :tabs="tabs" v-model:activeTab="activeTab"></live-tabs>
        <ht-chat-view v-show="activeTab===0" class="chat_view"/>
        <ht-member-view v-show="activeTab===1" />
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import TextGo from '@/components/TextGo.vue';
import LiveTabs from '@/components/LiveTabBar.vue';
import { mapGetters } from 'vuex';
import HtCameraView from './components/htCameraView.vue';
import MainPlayer from './components/mainPlayer.vue';
import HtChatView from './components/htChatView.vue';
import HtMemberView from './components/htMemberView.vue';
import HtToolView from './components/htToolView.vue';
import HtBarrage from './components/htBarrage.vue';
import TeacherView from './components/TeacherView.vue';
// import EvaluationTip from './components/EvaluationTip.vue';
import LiveNav from './components/Nav.vue';
import { connectState } from '../../utils/wyUtil';

export default {
  name: 'htLive',
  components: {
    HtCameraView,
    MainPlayer,
    HtChatView,
    HtMemberView,
    HtToolView,
    HtBarrage,
    TextGo,
    TeacherView,
    LiveNav,
    LiveTabs,
    // EvaluationTip,
  },
  data() {
    return {
      HTSDK:window.HTSDK,
      timeInfo: { // 直播时间相关信息
        currentTime: 0,
        duration: 0,
        restTime: 0,
      },
      activeTab: 0,
      noticeVisible: false, // 公告信息是否展示
      showEvaluationTip: false, // 评价组件是否展示
      switchMode:false,//摄像头切换模式
      notice: null, // 公告内容
      LiveTime: null,
      leftTime: null,
      endTime: null,
    };
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'sectionDetail',
    ]),
    ...connectState('live', [
      'barrages', 'barragePause', 'classStatus', 'onLineNumber', 'showCamera', 'sideViewFold', 'switch',
    ]),
    tabs(){
      return [// 右侧tabs
        { label: '讨论区' }, { label: '成员('+this.onLineNumber+")" },
      ]
    }
  },
  watch:{
    switch(val){
      this.switchMode=val
    }
  },
  created() {
    this.HTSDK=window.HTSDK
    this.HTSDK.on(this.HTSDK.EVENT.CAMERA.START, (res) => {
      this.updateModelState({ key: 'showCamera', data: true });
    });
    // 公告
    this.HTSDK.on(this.HTSDK.EVENT.NOTICE.PUBLIC, (res) => {
      this.notice = res.content;
      this.noticeVisible = true;
    });
    // 上课时间
    this.HTSDK.on(this.HTSDK.EVENT.LIVE.TIME, (res) => {
      this.LiveTime = res;
    });
    // 直播状态 0 未开始 1 直播中 -1 已结束
    this.HTSDK.on(this.HTSDK.EVENT.LIVE.WAIT, () => {
      this.updateModelState({ key: 'classStatus', data: 0 });
    });
    this.HTSDK.on(this.HTSDK.EVENT.LIVE.START, () => {
      this.updateModelState({ key: 'classStatus', data: 1 });
    });
    this.HTSDK.on(this.HTSDK.EVENT.LIVE.STOP, () => {
      this.updateModelState({ key: 'classStatus', data: -1 });
      this.showEvaluationTip = true;
    });
    this.HTSDK.on(this.HTSDK.EVENT.LIVE.IS_COURSE, (data) => {
      if (data.info.action === 'stop') {
        this.endTime = parseInt(data.info.endTime) * 1000;
        this.updateModelState({ key: 'classStatus', data: -1 });
      } else if (data.info.action === 'start') {
        this.updateModelState({ key: 'classStatus', data: 1 });
      }
    });
    // 踢出直播间
    this.HTSDK.on(this.HTSDK.EVENT.MEMBER.KICK, (res) => {
      console.log('kick user:', res);
      this.$message.error('您已被管理员移出出房间!');
      this.$router.replace('/404');
      // 主动下讲台
    });
    // 账号被挤下线
    this.HTSDK.on(this.HTSDK.EVENT.MEMBER.FORCE_OUT, (res) => {
      console.log('force out:', res);
      this.$message.error('您已在其他设备登录!');
      this.$router.replace('/404');
      const me = this.$store.state.live.ME || {};
      this.HTSDK.emit(this.HTSDK.EVENT.RTC.DOWN, { xid: me.xid || 0 }, (res) => {
        // 0 未申请 1 申请中 2 连麦中，
        res.code === 0 && this.updateModelState({ key: 'applyStatus', data: 0 });
      });
    });
    // 当前直播间返回的用户信息
    this.HTSDK.on(this.HTSDK.EVENT.MEMBER.CURRENT_USER, (user) => {
      this.updateModelState({ key: 'ME', data: user });
    });
    // 用户上讲台信息
    this.HTSDK.on(this.HTSDK.EVENT.SDK.MOUNTED, (res) => {
      const { upUserData } = this.HTSDK.getData().rtc;
      if (!upUserData) return;
      this.updateModelState({ key: 'upUserData', data: upUserData });
      const user = Object.values(upUserData).find((obj) => obj.uid * 1 === this.userInfo.userId * 1);
      if (user) {
        user.uid==this.userInfo.userId&&this.updateModelState({ key: 'applyStatus', data: 2 });
      }
    });
    // 用户上讲台
    this.HTSDK.on(this.HTSDK.EVENT.RTC.UP, (user) => {
      // 如果上讲台是是当前用户，更新状态
      if (this.userInfo.userId * 1 === user.uid * 1) {
        // 销毁flv,播放rtc
        this.HTSDK.flvPlayer.destroy();
      }
    });
    // 连麦用户发生变化时会触发，获取当前连麦用户
    this.HTSDK.on(this.HTSDK.EVENT.RTC.UP_USER_LIST, (res) => {
      this.updateModelState({ key: 'upUserList', data: res });
    });
    // 讲台操作
    this.HTSDK.on(this.HTSDK.EVENT.RTC.OPEN, (res) => {
       console.log("----up ")
    })
    this.HTSDK.on(this.HTSDK.EVENT.RTC.CLOSE, (res) => {
      this.updateModelState({ key: 'platformStatus', data: false });
      // 更新用户连麦状态
      this.updateModelState({ key: 'applyStatus', data: 0 });
    });
  },
  methods: {
    checkClassStatus(status) {
      return this.classStatus === status;
    },
    updateModelState(payload) {
      this.$store.commit('live/UPDATE_STATE_VALUE', payload);
    },
    handleSwitchView() {
      this.updateModelState({ key: 'switch', data: !this.switchMode });
    },
    closeNotice() {
      this.noticeVisible = false;
    },
    getCourseTime() {
      const { beginTime, endTime } = this.sectionDetail;
      return this.checkClassStatus(0) ? beginTime : endTime;
    },
    // closeWindow() {
    //   window.opener = null;
    //   window.open('about:blank', '_top');
    //   window.close();
    // },
  },
};
</script>

<style scoped lang="scss">
  .live_page_conatiner{
    width:100vw;
    height:100vh;
    overflow: hidden;
    display:flex;
    flex-direction: column;
  }

    .main_container_wrapper {
        display: flex;
        flex: 1;
    }

    .main_container {
        flex: 1;
        background-color: #fafafa;
        position: relative;
        transition: width 0.2s ease;
        border-right: 1px solid #EAEAEA;
        box-sizing: border-box;
    }

    .user_part .label {
        margin-right: 60px;
    }

    .user_part .user_teacher {
        margin-right: 26px;
    }

    .primary_text {
        color:#3377FF;
    }

    .file_video_tip {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        text-align: center;

        .bgImg {
            width: 320px;
            height: 164px;
            /*background: url('~assets/images/waiting.png') no-repeat center;*/
            background-size: contain;

            &.waitClass {
                /*background: url('~assets/images/weishangke.png') no-repeat center;*/
                background-size: contain;
                width: 114px;
                height: 114px;
                margin: 0 auto;
            }
        }

        .tip {
            color: #D0D0D0;
            font-size: 20px;
            margin-top: 20px;
        }

        .course {
            margin-top: 30px;
            text-align: left;

            > p {
                color: #909090;
                font-size: 16px;
                line-height: 24px;
                margin-top: 8px;
            }
        }
    }

    .main_box {
        position: absolute;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        right: 0px;
        bottom: 53px;
    }

    .camera_box {
        background-color: #FFFFFF;
        position: fixed;
        top: 60px !important;
        right: 0px !important;
        width: 320px;
        height: 210px;
        z-index: 300;
    }

    .switch_view {
        position: fixed;
        width: 24px;
        height: 24px;
        right: 273px;
        top: 233px;
        cursor: pointer;
        /*z-index: 302;*/
      z-index: 310;
    }

    .side_view {
        background-color: #FFFFFF;
        width: 320px;
        height: 100%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        z-index: 101;
        transition: width ease 0.2s;
        overflow: hidden;
        position: relative;
    }
    .hide_side{
      width:0;
    }
    .show_side{
      width:320px;
    }

    .side_view .public-notice {
        color: #3377FF;
        font-size: 12px;
        padding: 16px;
        cursor: pointer;
        position: absolute;
        background: rgba(250, 250, 250, 1);
        left: 0;
        right: 0;
        top: 258px;
        text-align: center;
        z-index: 1001;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);

        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 12px;
        }

        .close_btn {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 18px;
            height: 18px;
            cursor: pointer;
        }
    }

    .chat_tabs {
        height: 48px;
        border-top: 1px solid #EAEAEA;
        border-bottom: 1px solid #EAEAEA;

        a {
            display: inline-block;
            width: 50%;
            height: 100%;
            line-height: 48px;
            text-align: center;
            font-size: 16px;
            box-sizing: border-box;

            &:first-child {
                border-right: 1px solid #EAEAEA;
            }

            &.active {
                border-top: 4px solid #3377FF;
                background-color: #F5F5F5;
            }
        }
    }

    .chat_view {
        flex: 1;
    }

    .tool_view {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 53px;
        background-color: #FFFFFF;
        box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
    }
</style>
