<template>
  <authentication>
    <live-page-nav play-type="live" :course-name="name"></live-page-nav>
  </authentication>
</template>
<script>
import Authentication from '@/components/Authentication/index.vue';
import LivePageNav from '../../../components/LivePageNav.vue';

export default {
  components: { LivePageNav, Authentication },
  name: 'liveNav',
  props: {
    name: String,
  },

};
</script>
<style lang="scss" scoped>
  .title_wrapper{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .icon_wrapper{
      width: 88px;
      height: 30px;
      line-height: 30px;
      background:#FFF;
      border-radius:4px;
      color:#3377FF;
      margin-right:20px;
      padding:0 8px;

      .header_live_icon{
        display:inline-block;
        width: 13px;
        height: 13px;
        background:url(../../../assets/img/course/header_live_icon@2x.png) 0 0 no-repeat;
        background-size:100% 100%;
        margin-right:10px;

      }
    }
  }

</style>
