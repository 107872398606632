<template>
  <div>
    <div class="emotion-box">
      <div class="emotion-box-line" v-for="(src, key) in list" :key="src">
        <img
          :src="src"
          @click.stop="clickHandler(key)"
          class="emotion-item"
          alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { HT_EMOTIONS_PACKAGE } from '@/utils/htTool';

export default {
  props: {
    height: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      list: HT_EMOTIONS_PACKAGE,
    };
  },
  methods: {
    clickHandler(key) {
      console.log(key);
      this.$emit('emotion', key);
    },
  },
  components: {},
};
</script>
<style scoped>
  .emotion-box {
    margin: 0 auto;
    width: 200px;
    box-sizing: border-box;
    padding: 5px;
    /*border: 1px solid #b4b4b4;*/
    overflow: hidden;
    overflow-y: auto;
  }

  .emotion-box-line {
    display: inline-flex;
    margin: 5px;
  }

  .emotion-item {
    /* flex: 1; */
    text-align: center;
    cursor: pointer;
    flex: none;
    width: 26px;
  }
</style>
