<template>
    <div class="teacher_avatar_box" v-if="Object.keys(sectionDetail).length">
            <img v-if="courseTeacherAvatar"
                 :src="courseTeacherAvatar"
                 class="teacher_avatar"
                 alt=""/>
            <img
              v-else
              src="../../../assets/img/icon_teacher_nor.png"
              class="teacher_avatar"
              alt=""/>
            <div class="teacher_name">{{courseTeacherName}}</div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';

export default {
  name: 'TeacherView',
  computed: {
    ...mapGetters([
      'sectionDetail',
    ]),
    courseTeacherAvatar() { // 上课老师名
      // const teacherInfo = this.courseInfo.lesson_info.teacher_infos.find((item) => item.main_status === 1);
      // return teacherInfo ? teacherInfo.img_url : '';
      return '';
    },
    courseTeacherName() { // 上课老师名
      // const teacherInfo = this.courseInfo.lesson_info.teacher_infos.find((item) => item.main_status === 1);
      // return teacherInfo ? teacherInfo.pet_name : '';
      return this.sectionDetail.teacherName;
    },
  },
};
</script>

<style scoped lang="scss">
    .teacher_avatar_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 210px;
        position: relative;

        #bypassStream {
            background-color: #313131;
            width: 100%;
            height: 100%;
        }

        div {
            width: 100%;
            text-align: center;
        }

        .teacher_avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }

        .teacher_name {
            font-size: 16px;
            margin-top: 10px;
        }
    }
</style>
