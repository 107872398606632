/* eslint-disable */
export const HT_EMOTIONS_PACKAGE = {
  '[aha]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/001.gif',
  '[hard]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/002.gif',
  '[good]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/003.gif',
  '[love]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/004.gif',
  '[flower]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/005.gif',
  '[S_FLOWER]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/005.gif',
  '[cool]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/006.gif',
  '[why]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/007.gif',
  '[pitiful]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/008.gif',
  '[amaz]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/009.gif',
  '[bye]': 'https://static-1.talk-fun.com/open/cooperation/common/emotions/default/010.gif',
};
export const ubb2img = function (text) {
  return text.replace(/\[.+?\]/g, (e) => (void 0 !== HT_EMOTIONS_PACKAGE[e] ? `<img src="${HT_EMOTIONS_PACKAGE[e]}"/>` : e));
};
export const toHTML = function (e) {
  return e.replace(/\[IMG\]([^\[]*)\[\/IMG\]/gi, (e, t) => `<img class="clip" src="${t}" />`);
};
