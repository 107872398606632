<template>
  <main class="player-view">
    <img
      class="play_btn"
      v-show="!playing && liveMode === 1"
      src="../../../assets/img/play_video@2x.png"
      @click="playVideo"
      alt=""
    />
    <div
      class="player"
      style="position: absolute !important"
      :class="checkClassStatus(1) ? 'black' : null"
      :style="{
        transform: liveMode === 0 ? 'translateX(0)' : 'translateX(101%)',
      }"
      id="contain-whiteboard"
    ></div>
    <video
      class="player"
      @play="playing = true"
      :volume="volume"
      autoplay
      preload
      :style="{
        transform: liveMode === 1 ? 'translateX(0)' : 'translateX(101%)',
      }"
      id="VIDEO-CONTAINER"
      @waiting="videoWaiting"
      @canplay="videoCanPlay"
      @loadstart="videoLoadStart"
      @durationchange="videoDurationchange"
      @loadedmetadata="videoLoadedmetadata"
      @error="videoError"
      @stalled="videoStalled"
    />
  </main>
</template>

<script>
/* eslint-disable */
import { connectState } from "../../../utils/wyUtil";

export default {
  name: "mainPlayer",
  data() {
    return {
      HTSDK: window.HTSDK,
      liveMode: 0, // 直播模式：模式切换监听
      playing: false, // 是否正在播放中
    };
  },
  computed: {
    ...connectState("live", ["classStatus", "volume"]),
  },
  watch: {
    volume(newVal) {
      const video = document.getElementById("VIDEO-CONTAINER");
      video.volume = newVal;
    },
  },
  mounted() {
    this.HTSDK = window.HTSDK;
    this.HTSDK.registerModule("MOD_WHITEBOARD", {
      id: "contain-whiteboard",
    });
    // 模式切换
    this.HTSDK.on(this.HTSDK.EVENT.LIVE.FLV_STREAM, (res) => {
      if (this.$store.state.live.applyStatus !== 2) {
        if (res.type === "screen") {
          this.HTSDK.flvPlayer.destroy();
          this.liveMode = 1;
          this.HTSDK.flvPlayer.fire("#VIDEO-CONTAINER");
        } else if (res.hasOwnProperty("type")) {
          this.HTSDK.flvPlayer.destroy();
          this.liveMode = 0;
          this.HTSDK.flvPlayer.fire("#bypassStream");
        }
      }
      if (this.$store.state.live.lineSetting) {
        this.updateModelState({
          key: "lineSetting",
          data: false,
        });
        this.$message({ message: "线路切换成功！", type: "success" });
      }
    });
    // 模式切换
    this.HTSDK.on(this.HTSDK.EVENT.RTC.MODE_CHANGE, (res) => {
      console.log("mode change:", res);
      // 返回的res是一个数组，如果是['0', '2']代表是当前是桌面分享模式，如果是['1','0']代表当前是课件模式,[0,1]是播放视频
      this.HTSDK.flvPlayer.destroy();
      const mode = parseInt(res[1]);
      if (mode === 2 || mode === 1) {
        this.liveMode = 1;
        this.updateModelState({ key: "liveMode", data: 1 });
        setTimeout(() => {
          this.HTSDK.flvPlayer.fire("#VIDEO-CONTAINER");
        }, 5000);
      } else {
        this.liveMode = 0;
        this.updateModelState({ key: "liveMode", data: 0 });
        if (this.$store.state.live.applyStatus !== 2) {
          setTimeout(() => {
            this.HTSDK.flvPlayer.fire("#bypassStream");
          }, 5000);
        }
      }
      this.HTSDK.flvPlayer.play();
    });
    // 播他人的流
    this.HTSDK.on(this.HTSDK.EVENT.RTC.PLAY_REMOTE, (params) => {
      if (parseInt(params.streamId) === 1) {
        // 这里播主播的流
        params.callback("zhu_bo_stream");
      } else {
        params.callback(`user_stream${params.user.xid}`);
      }
    });
  },
  methods: {
    checkClassStatus(status) {
      return this.classStatus === status;
    },
    updateModelState(payload) {
      this.$store.commit("live/UPDATE_STATE_VALUE", payload);
    },
    playVideo() {
      const video = document.getElementById("VIDEO-CONTAINER");
      video.play();
      this.playing = true;
    },
    videoStalled() {
      console.log("videoStalled");
    },
    videoError() {
      console.log("videoError");
    },
    videoWaiting() {
      // this.HTSDK.flvPlayer.fire('#VIDEO-CONTAINER')
      console.log("videoWaiting");
    },
    videoCanPlay() {
      console.log("videoCanPlay");
    },
    videoLoadedmetadata() {
      console.log("videoLoadedmetadata");
    },
    videoDurationchange() {
      console.log("videoDurationchange");
    },
    videoLoadStart() {
      console.log("videoLoadStart");
    },
  },
};
</script>

<style scoped lang="scss">
.player-view {
  height: 100%;
  .player {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.black {
      background-color: #000000;
    }
  }

  video {
    background-color: #000000;
  }

  .play_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    cursor: pointer;
    z-index: 100;
  }
}
</style>
