<template>
  <section v-show="showCamera && classStatus === 1">
    <div v-show="applyStatus !== 2" class="ht_cameraView">
      <img
        class="close_btn"
        v-show="classStatus === 1"
        src="../../../assets/img/camera_close@2x.png"
        @click="closeVideo"
        alt=""
      />
      <img
        class="play_btn"
        v-show="!playing"
        src="../../../assets/img/play_video@2x.png"
        @click="playVideo"
        alt=""
      />
      <video
        id="bypassStream"
        ref="camera"
        autoplay="autoplay"
        preload
        @timeupdate="handleTimechange"
        @play="playing = true"
        @pause="playing = false"
        @error="playing = false"
        v-show="showCamera"
      />
    </div>
    <div v-show="applyStatus == 2" class="stream_wrapper">
      <div id="zhu_bo_stream"></div>
      <div v-for="(item, key) in upUserList" :key="key">
        <div v-if="item.uid !== userInfo.id" :id="'user_stream' + key"></div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import { connectState } from "../../../utils/wyUtil";

export default {
  name: "htCameraView",
  data() {
    return {
      playing: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...connectState("live", [
      "showCamera",
      "classStatus",
      "applyStatus",
      "upUserList",
      "volume",
      "liveMode",
    ]),
  },
  watch: {
    volume(val) {
      const video = document.getElementById("bypassStream");
      video.volume = val;
    },
    showCamera(val) {
      if (val) {
        this.playVideo();
      }
    },
  },
  methods: {
    handleTimeChange() {
      if (!this.playing) {
        this.playing = true;
      }
    },
    updateModelState(payload) {
      this.$store.commit("live/UPDATE_STATE_VALUE", payload);
    },
    switchView() {
      this.updateModelState({
        key: "switch",
        data: !this.$store.state.live.switch,
      });
    },
    closeVideo() {
      this.updateModelState({
        key: "showCamera",
        data: !this.$store.state.live.showCamera,
      });
    },
    playVideo() {
      const video = document.getElementById("bypassStream");
      video.play();
      this.playing = true;
    },
  },
};
</script>

<style scoped lang="scss">
.ht_cameraView {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
  position: relative;

  .close_btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    width: 38px;
    cursor: pointer;
    z-index: 100;
  }

  .play_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 45px;
    width: 45px;
    cursor: pointer;
    z-index: 100;
  }

  #bypassStream {
    background-color: #313131;
    width: 100%;
    height: 100%;
  }

  .switch_view {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 13px;
    bottom: 13px;
    cursor: pointer;
  }

  .teacher_avatar_box {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;

    div {
      width: 100%;
      text-align: center;
    }
  }

  .teacher_avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .teacher_name {
    font-size: 16px;
    margin-top: 10px;
  }
}
.stream_wrapper,
.stream_wrapper #zhu_bo_stream {
  height: 100%;
  width: 100%;
  background-color: #000000;
}
</style>
