<template>
    <div class="ht-chatMember">
        <section class="members-list">
            <div class="memberItem"
                 v-for="(item) in memberList"
                 :key="item.uid">
<!--                <img class="avatar" :src="item.avatar">-->
                <div class="teacher_symbol"
                     :class="isTeacher(item.role)?'teacher':null">
                  {{isTeacher(item.role)?"老师":"学员"}}
                </div>
                <div class="name">
                  {{item.nickname||item.uid}}
                </div>
            </div>
        </section>

    </div>
</template>

<script>

import { connectState } from '../../../utils/wyUtil';

export default {
  name: 'htMemberView',
  data() {
    return {
      totalNumber: 0,
      onLineNumber: 0,
      showSearch: false,
      searchText: '',
    };
  },
  computed: {
    ...connectState('live', ['memberList']),
  },
  created() {
  },
  mounted() {
  },
  methods: {
    isTeacher(role) {
      return ['spadmin', 'admin'].includes(role);
    },
    /**
             * 显示搜索框
             */
    showSearchInput() {
      this.showSearch = true;
      // 界面有改动，改动完成后再获取焦点
      this.$nextTick(() => {
        this.$refs.inputRef.focus();
      });
    },
    /**
             * 清空搜索框
             */
    clearSearchInput() {
      this.searchText = '';
      this.$refs.inputRef.focus();
    },
  },
};
</script>

<style scoped lang="scss">

    .ht-chatMember {
      height: calc(100vh - 330px);
      overflow: hidden;
      overflow-y: auto;
        .memberItem {
            display: flex;
            align-items: center;
            padding: 15px 15px 5px;
            font-size: 14px;
            color:#313131;
        }
      .teacher_symbol{
        width:35px;
        height:20px;
        line-height: 20px;
        font-size: 13px;
        text-align: center;
        background:#3377FF;
        color:#FFF;
        margin-right:5px;
        border-radius: 5px;
      }
      .teacher{
        background:#FF3366;
      }
        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 22px;
        }
    }
</style>
